import {
  ref, onMounted, getCurrentInstance, reactive, computed, watch, onBeforeUnmount
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { PrimeIcons } from 'primevue/api'

export default {

  props: {
    isShowDialog: {
      default: () => false
    },
    title: {
      default: () => 'Pilih Icon'
    },
  },
  emits: ['hideDialog', 'sendOutData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const propertiesProps = reactive(props)
    const isShowDialog = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const title = computed(() => propertiesProps.title)

    const store = useStore()

    const hideDialog = () => {
      emit('hideDialog')
    }
    const setIcon = (val: any) => {
      emit('sendOutData', val)
    }
    const inputSearch = ref('')
    const isShow = (val: any) => {
      if (inputSearch.value.length > 0) {
        return val?.icon.includes(inputSearch.value)
      }
      return true
    }
    const listIcons = ref([]) as any
    const setupIcon = () => {
      const icons: any = []
      Object.entries(PrimeIcons).forEach(
        ([key, value]) => icons.push({ view: value, icon: value.replace('pi ', '') })
      )
      listIcons.value = icons
    }
    onMounted(() => {
      setupIcon()
    })
    return {
      title,
      isShowDialog,
      store,
      hideDialog,
      inputSearch,
      setIcon,
      isShow,
      listIcons
    }
  }
}
